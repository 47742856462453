@import '../../../base/assets/scss/_base/config';

.m-mail {
  &-preview {
    cursor: pointer;
    margin: 0;
    position: relative;

    &--hidden .m-mail-preview__content {
      display: none;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }

    &__content {
      font-family: $font-family-base;
      height: auto;
      margin-top: -9px;
      opacity: 1;
      position: absolute;
      right: -45px;
      top: 40px;
      visibility: visible;
      z-index: 10;

      &--inner {
        background-color: white;
        border: 1px solid $bob;
        border-radius: 0;
        font-size: 14px;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 295px;
      }

      @media (min-width: $screen-xl) {
        right: -39px;
      }
    }

    &__placeholder {
      align-items: center;
      display: flex;
      height: 385px;
      justify-content: center;
    }

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &__item {
      border-bottom: 1px solid $bob;
      padding: 5px;

      &--unread{
        .m-mail-preview__item--subject{
          &:before{
            content: '';
            height: 6px;
            width: 6px;
            background-color: $dory;
            display: inline-block;
            border-radius: 50%;
            vertical-align: top;
            margin: 2px 3px 0 0;
          }
        }

      }

      &:hover {
        background-color: $marshmallow;

        .m-mail-preview__item--subject {
          color: $dory;
        }
      }

      &--link {
        color: $batcave;
        display: block;
        padding: 5px;
        text-decoration: none;
      }

      &--meta {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      &--sender,
      &--time {
        color: $charcoal;
        font-size: 12px;
        white-space: nowrap;
      }

      &--sender {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--time {
        flex: 1 0 auto;
        text-align: right;
        text-decoration: underline;
      }

      &--subject {
        color: $batcave;
        font-size: 15px;
        margin: 0 0 5px;
      }

      &--digest {
        backface-visibility: hidden;
        font-size: 12px;
        -webkit-line-clamp: 2;
        line-height: 1.338;
        margin: 0;
        max-height: 33px;
        overflow: hidden;
      }
    }

    &__footer {
      background-color: $grey_hair;
      display: flex;
      justify-content: space-between;
      padding: 8px 10px 4px;

      &--all,
      &--compose {
        color: $battleship;
        font-size: 12px;
        text-decoration: none;

        &:hover {
          color: $dory;
        }
      }
    }

    .navicon {
      position: relative;
    }
  }

  &-count {
    background-color: $dory;
    border-radius: 15px;
    color: white !important;
    display: none !important;
    font-weight: bold;
    z-index: 1;

    .mail-badges & {
      display: block !important;
    }
  }

  &-count--usernav {
    font-size: 10px !important;
    line-height: 0.8;
    padding: 4px 5px;
    position: absolute;
    right: -10px;
    top: -6px;

    @media (min-width: 900px) {
      right: 0;
    }
  }

  &-count--navrail {
    font-size: 12px;
    height: 22px;
    left: 100px;
    line-height: 1;
    padding: 6px 10px 6px 12px;
    position: absolute;
    top: 6px;

    .minimised &{
      font-size: 10px;
      height: auto;
      left: 10px;
      line-height: .9;
      padding: 4px 5px;
      top: 5px;
    }
    @media (min-width: 900px) and (max-width: 1264px) {
      font-size: 10px;
      height: auto;
      left: 10px;
      line-height: .9;
      padding: 4px 5px;
      top: 5px;
    }
  }
}
