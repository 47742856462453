@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';
@import '../../../gam/assets/scss/gam';
@import '../../../special_event/assets/scss/special_event';
@import './mail';
@import './banner';
@import './flyout';
@import "./grid";
@import './lnav_with_backdrop';

$navBackground: #66b3ff;
.m-AolHeader {
  margin: auto;
  margin-bottom: 0 !important;
  width: 100%;

  &.m-AolHeader--sticky{
    display: inline;
    display: contents;

    &.m-AolHeader--navV2{
      .top-wrap{
        z-index: 500;
        height: auto;
        .m-AolHeader--shadow {
          top: auto;
          bottom:-32px;
          @media screen and (min-width: map-get($breakpoints, s)) {
            bottom:-42px;
          }
        }
      }
    }

    .m-AolHeader__top{
      position: sticky;
      top: 0;
      margin-bottom: 36px;
    }

    .m-AolHeader__spacer--header{
      display: none;
    }
  }

  &.minimised {
    .m-AolHeader--innerContainer {
      margin: 0;
      max-width: 100%;
    }
  }

  &--shadow {
    height: 32px;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
    pointer-events: none;
  }

  &--lnav_with_backdrop { 
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
  }

  &--innerContainer {
    max-width: 1405px;
    margin: 0 auto;
  }

  &--innerWrapper {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 200;
  }

  &--noSearch{
    .m-AolHeader__spacer {
      &--header {
        height: 51px;
      }
    }
  }
  &__spacer {
    &--header {
      height: 100px;
    }
  }

  &.m-AolHeader--search-hidden #header-form {
    display: none;
  }

  .top-wrap {
    position: relative;
    height: 42px;
    top: 0;
    width: 100%;
    background-color: white;
  }

  .left-wrap {
    padding-top: 28px;
    position: absolute;
    background-color: transparent;
  }

  .title-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .logo-link {
      img {
        cursor: pointer;
        display: block;
        height: 42px;
        margin-left: 20px;
        width: 42px;
      }
      @media (max-width: 900px) {
        svg {
          padding-top: 10px;
        }
      }
    }

    .experience-logo {
      img {
        cursor: pointer;
        display: block;
        height: 40px;
        width: 50px;
      }
    }

    .o2-logo img {
      background-size: 86px 26px;
      width: 86px;
    }

    .title-text {
      color: $batcave;
      font-size: 14px;
      font-weight: normal;
      left: 50%;
      letter-spacing: 0.1em;
      margin: 0 auto;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 12px;
      transform: translate(-50%);
    }

    .toggle-buttons {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;


      .navigation-toggle {
        cursor: pointer;
        position: absolute;
        right: 15px;
        width: 24px;
        .hamburger-close-icon {
          display: none;
        }
        svg {
          position: absolute;
          z-index: 10;
          top: -5px;
          left: 3px;
        }
      }
    }
  }
  .header-24_7-help { 
    .m-profile__signin, .m-profile__btn {
      order: 2;
    }
    .m-cs {
      .m-cs__btn {
        padding: 14px 7px 14px 7px;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px; 
      }
      
      @media screen and (max-width: map-get($breakpoints, xs)) {
        .m-cs__btn-24_7-help {
          display: none;
        }
      }
    }
  }

  #header-form {
    display: flex;
    padding: 10px;

    .header-search-form {
      position: relative;
      width: 100%;

      &.wafer-autocomplete-active {
        #header-form-search-input {
          border-bottom-left-radius: 0;
        }
        #header-form-search-button {
          border-bottom-right-radius: 0;
        }
      }
    }

    #header-form-search-input {
      border: 1px solid $bob;
      border-radius: 0;
      flex: 1;
      float: left;
      font-size: 20px;
      height: 40px;
      line-height: 20px;
      max-width: calc(100% - 80px);
      outline: none;
      padding: 0 12px;
      width: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    #header-form-search-button {
      background-color: $dory;
      border: none;
      transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 40px;
      svg {
        fill: $white;
        height: 100%;
      }
    }

    #header-form-search-input-list {
      background-color: $white;
      display: block;
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 100;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      ul {
        border: 1px solid $bob;
        border-top: 0;
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: left;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        li {
          cursor: pointer;
          padding: 7px 5px;

          &:hover {
            background: $gray-lighter;
          }
        }
      }
    }

    #header-form-search-button:hover,
    #header-form-search-button:focus,
    #header-form-search-button:active {
      background-color: $dory-hover;
    }
  }

  .desktop-user-navigation {
    .user-navigation {
      display: none;
      z-index: 600;

      /* flex when shown */
      justify-content: space-around;
      padding: 18px 0 0 0;
      width: auto;
      // Desktop only els hidden-by-default
      .user-navigation-menu-amazon {
        display: none;
      }

      .login-button,
      .profile-button,
      .help-button,
      .mail-button {
        align-items: center;
        background-color: $dory;
        border-radius: 5px;
        color: $white;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-size: 12px;
        height: 68px;
        justify-content: center;
        letter-spacing: 0.1em;
        margin: 10px;
        text-decoration: none;
        text-transform: uppercase;
      }

      .m-wsrefresh {
        margin: 0;
        padding: 0 3px;
        position: absolute;
        right: 0;
        top: -10px;

        a {
          color: $dory;
          font-size: 11px;
          padding: 0;
          text-decoration: none;
        }
      }
    }
  }

  .navrail {
    display: none;
    flex-direction: column;

    /* flex when shown */
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 175px; // this is to allow veritcle scrolling while taking into account variable banners
    // Desktop only el hidden-by-default
    .mail-link,
    .profile-link {
      display: none;
    }

    > li {
      align-items: center;
      background-color: $dory;
      display: block;
      font-size: 15px;
      height: auto;
      letter-spacing: 0.1em;
      margin: 0 0 1px;
      position: relative;

      .navrail_subitem {
        &.parent_label {
          &:hover {
            background-color: unset !important;
            cursor: default !important;
          }
        }

        &:hover {
          cursor: pointer;
        }
    
        .nav-sub-item-svg {
          top: 2px;
          position: relative;
          
          &.active {
            fill: $dory;
          }
        }

        @media screen and (min-width: map-get($breakpoints, s)) {
          .nav-sub-item-svg {
            top: 1px;
            @media screen and (min-width: map-get($breakpoints, s)) and (max-width: map-get($breakpoints, l)) {
              left: 6px;
            }
          }

          &:hover {
            .navicon svg,
            .nav-sub-item-svg {
              fill: $dory !important;
            }
    
            .text-label {
              color: $dory !important;
            }
          }
        }
      }

      > a,
      .navrail_subitem,
      .user-profile-button {
        color: $white;
        display: block;
        flex: 1;
        height: 60px;
        padding: 0;
        position: relative;
        text-align: left;
        text-decoration: none;
        text-transform: uppercase;
      }

      .user-profile-button a {
        line-height: 15px;
        text-decoration: none;
      }

      .user-profile-button .logout-button {
        display: none;

        .text-label {
          color: #999;
          font-size: 12px;
          padding: 0 0 7px 27px;
        }
      }

      a:hover {
        .navicon svg {
          fill: $dory !important;
        }

        .text-label {
          color: $dory;
        }
      }

      .navicon {
        display: block;
        font-size: 19px;
        left: 0;
        line-height: 17px;
        margin-left: 20px;
        margin-top: 0;
        padding: 0;
        position: absolute;
        top: 22px;

        svg {
          height: 17px;
          width: 17px;
        }

        &.active {
          svg {
            fill: $dory !important;
          }
        }

        &__img{
          margin-left: -5px;
          height: 12px;
          margin-bottom: -2px;
          width: auto;
        }
      }

      .text-label {
        font-family: $font-family-base;
        font-size: 14px;
        padding: 22px 54px;
        position: relative;
        top: 20px;

        &.active {
          color: $dory;
        }

        &__badge{
          font-family: $font-family-poppins;
          font-weight: 700;
          font-size: 12px;
          line-height: 1;
          color: $white;
          background-color: $bonsai;
          border-radius: 10px;
          padding: 3px 6px;
        }
      }

      .navrail-parent-label {
        color: $black;
        font-family: $font-family-poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding-left: 20px !important;
        
        @media screen and (min-width: map-get($breakpoints, s)) {
          padding-left: 0px !important;
        }
      }

      .count {
        color: #fff;
        background-color: #0047ff;
        border-radius: 15px;
        font-size: 10px;
        width: 15px;
        height: 15px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        white-space: nowrap;
        top: 11px;
        left: 32px;
        position: absolute;
        display: flex;
      }

      .navrail-submenu {
        display: inline-block;
        height: auto;
        list-style-type: none;
        padding: 0;
        position: relative;
        text-align: left;
        width: 100%;

        li {
          &:first-child{
            border-top: 1px solid $dirty_seagull;
          }
          padding: 0 28px;
          border-bottom: 1px solid $dirty_seagull;
          display: block;
          position: relative;
          &:after{
            width: 100% !important;
          }

          @media screen and (min-width: map-get($breakpoints, s)) {
            padding: 0 0 0 20px !important;
          }

          a {
            background-color: $marshmallow;
            display: block;
            padding-left: 60px !important;
            text-decoration: none;
            @media screen and (min-width: map-get($breakpoints, s)) {
              padding-left: 0px !important;
              background-color: transparent;
            }
            span {
              color: $batcave;
              font-size: 16px;
              letter-spacing: 0;
              padding: 0;
              top: 0;
              vertical-align: middle;
            }
            .subitem-link.active {
              color: #999;
            }
          }
        }
      }

      .submenu-wrapper {
        .subChannelsHidden {
          display: none;
        }
      }
    }

    &__divider {
      padding: 16px 36px;
      @media screen and (min-width: 901px) {
        padding: 16px;
      }
      @media screen and (min-width: map-get($breakpoints, l)) {
        padding: 16px 0 16px 20px !important;
      }

      &--line {
        background-color: $dirty_seagull;
        width: 100%;
        height: 1px;
      }
    }
  }

  // Desktop only el hidden-by-default
  .m-mtmhp-leftrail {
    display: none;
  }

  &.m-AolHeader--newNav {
    &.minimised{
      .navrail {
        > li {
          .m-mail-count--navrail{
            position: absolute;
            left: auto;
          }
        }
      }
    }
    .navrail {
      .mail-link,
      .profile-link {
        display: block;
      }

      > li {
        .user-profile-button {
          height: auto;
          .logout-button {
            display: none;
            padding: 0;
            .text-label {
              font-size: 16px;
              padding: 0 0 16px 80px;
              color: $dolphin;
              display: block;
            }
            &:hover {
              background-color: initial;
              .text-label {
                color: $dory;
              }
            }
          }
        }
        .m-mail-count--navrail {
          position: static;
          @media screen and (min-width: 901px) and (max-width: map-get($breakpoints, l)) {
            position: absolute;
            left: auto;
          }

          margin-left: 10px;
          font-weight: 700;
          padding: 6px;
          letter-spacing: 0;
          border-radius: 20px;
        }

        background-color: transparent;
        .user-profile-button > a,
        > a,
        .navrail_subitem {
          color: $batcave;
          line-height: 1;
          height: auto;
          padding: 16px 0 16px 20px;
          display: none;
          justify-content: flex-start;
          align-items: center;
          text-transform: none;
          letter-spacing: 0;
          .navicon {
            float: none;
            position: static;
            svg, img {
              display: block;
              width: 20px;
              height: 20px;
              fill: $batcave;
            }
          }
          .text-label {
            font-size: 16px;
            margin: 0;
            padding: 0 8px 0 20px;
            position: static;
          }
        }

        button.navrail_subitem {
          background-color: transparent;
          border: none;
          width: 100%;
    
          &:hover {
            background-color: $marshmallow;
          }
        }

        // 22px logo
        &.weather-link {
          > a {
            .text-label{
              padding-left: 19px;
            }
            .navicon {
              svg {
                width: 22px;
                height: 22px;
              }
            }
          }
        }
        //24px logo
        &.elections-link,
        &.fitness-link,
        &.lifestyle-link
        {
          > a {
            .text-label{
              padding-left: 18px;
            }
            .navicon {
              svg {
                width: 24px;
                height: 24px;
                margin-left:-2px;

              }
            }
          }
        }

        .navrail-submenu li{
          border: none;

          &:last-of-type:after{
            content: none;
          }
        }
        .navicon-chevron-circle {
          background: none;
          border: none;
          position: absolute;
          padding-right: 30px;
          right: 0;
          height: 52px;
          z-index: 1;
          top: 0;
          display: flex;
          align-items: center;

          &.open{
            svg{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 901px) {

    &.m-AolHeader--signedIn #header-form.header-form--smaller{
      width: calc(100% - 567px);
    }

    .m-AolHeader__closeBtn {
      display: block;
    }

    .m-AolHeader--shadow,
    .m-AolHeader--lnav_with_backdrop {
      top: 60px;
    }

    .m-AolHeader__spacer--header {
      height: 90px;
    }

    .logo {
      padding: 0;
      padding-left: 10px;
    }

    .experience-logo {
      padding: 0;
    }

    .top-wrap {
      height: 60px;
    }

    .title-bar {
      padding-top: 10px;

      .logo-link img {
        height: 36px;
        margin-left: 0;
        width: 101px;
      }

      .experience-logo img {
        height: 40px;
        margin-left: 20px;
        width: auto;
      }

      .o2-logo {
        background-size: 86px 26px;
        width: 86px;

        img {
          width: 151px;
        }
      }

      .toggle-buttons,
      .title-text {
        display: none;
      }
    }

    .navigation-toggle {
      display: none;
    }

    .desktop-user-navigation {
      position: absolute;
      right: 0;
      top: 0;
      width: 320px;

      .user-navigation {
        box-sizing: content-box;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 0;
        width: auto;

        .login-button,
        .profile-button,
        .help-button,
        .mail-button {
          padding-bottom: 10px;
        }

        .mail {
          min-width: 40px;
        }

        .profile-button, .help-button {
          min-width: 98px;
        }

        .user-profile-button,
        .mail,
        .user-navigation-menu-amazon {
          display: flex;
          margin-right: 0;

          .login-button,
          .profile-button,
          .help-button,
          .logout-button,
          .mail-button {
            align-items: normal;
            background-color: transparent;
            color: $batcave;
            display: flex;
            flex-direction: row;
            height: auto;
            margin: 0 10px;
            position: relative;
            right: -20px;
            text-decoration: none;
            top: 3px;
            width: auto;

            svg {
              fill: $batcave;
              height: 17px;
              line-height: 18px;
              margin-right: 10px;
              position: relative;
              top: 0;
              width: 17px;
            }

            span {
              color: $batcave;
              display: inline-block;
              font-family: $font-family-base;
              font-size: 14px;
              letter-spacing: 0;
              text-transform: none;
              transition: color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
              width: max-content;
            }

            .text-label {
              max-width: 73px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .text-label-width {
              max-width: 148px;
            }

            .help-navicon{
              position: relative;
              top:2px;
            }
          }

          .amazon-link {
            margin-left: 10px;
            position: relative;
            top: 3px;

            img {
              height: 20px;
              width: 20px;
            }
          }

          .profile-button:hover,
          .help-button:hover,
          .mail-button:hover,
          .logout-button:hover {
            svg {
              cursor: pointer;
              fill: $dory;
            }

            .text-label {
              color: $dory;
              cursor: pointer;
            }
          }

          .mail-button .text-label {
            display: none;
          }
        }

        .user-navigation-menu-amazon {
          cursor: pointer;
          display: flex;
          margin-left: 10px;
          right: 0;
        }

      }
    }

    #header-form {
      left: 150px;
      max-width: 650px;
      position: relative;
      top: -42px;
      width: calc(100% - 442px);
      padding: 0;

      &.header-form--smaller{
        width: calc(100% - 500px);
      }

      &.o2-logo {
        left: 140px;
      }

      .header-search-form {
        display: flex;
        margin: 0 auto;
        max-width: 650px;
        width: 100%;
      }

      #header-form-search-input {
        width: 100%;
      }
    }

    .left-wrap {
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar
      &::-webkit-scrollbar { // Webkit Hide scrollbar
        display: none;
      }
      overflow-y: scroll;
      height: 100vh;
      clear: both;
      display: flex;
      flex-direction: column;
      float: left;
      width: 60px;
    }

    .navrail {
      display: flex;
      flex-direction: column;
      // Desktop only el hidden-by-default
      .mail-link,
      .profile-link {
        display: block;
      }

      .d-hidden{
        display: none;
      }

      li {
        background-color: transparent;
        margin: 0;
        padding-left: 20px;

        > a,
        .navrail_subitem,
        .user-profile-button {
          height: 40px;

          .navicon {
            display: block;
            float: left;
            margin-left: 0;
            position: relative;
            top: 12px;

            svg {
              fill: $batcave;
              height: 17px;
              width: 17px;
            }
          }
        }

        .nav-sub-item-svg {
          display: flex;

          &.active {
            fill: $dory;
          }
        }

        &.travel-link .navicon svg {
          fill: none;
          stroke: $batcave;
          stroke-width: 10px;
        }

        .user-profile-button .logout-button {
          display: block;
          padding-top: 5px;
          &:hover {
            .text-label {
              color: #bfbfbf;
            }
          }
        }

        .navicon-chevron-circle{
          display: none!important;
        }

        .text-label,
        .text-label__badge,
        .navicon__img,
        .navrail-submenu {
          display: none;
        }

        &.item--badge{
          a {
            .navicon{
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: nowrap;

              &:after{
                content: '';
                display: block;
                height: 10px;
                width: 10px;
                background-color: $bonsai;
                border-radius: 10px;
                margin-left: 7px;
              }
            }
          }
        }
      }
    }

    .m-mtmhp-leftrail {
      background-color: $dory;
      display: flex;
      height: 48px;
      position: relative;
      width: 60px;

      .mtmhp {
        width: 100%;

        .m-make-homepage {
          height: 100%;
          width: 100%;

          .icon {
            margin-top: 15px;
            margin-left: -4px;

            height: 17px;
            width: 17px;
          }

          a {
            height: 100%;
            text-decoration: none;
            width: 100%;
          }

          a:hover {
            background: $dory-hover;
            cursor: pointer;
          }

          .text-label {
            display: none;
          }
        }
      }
    }

    &.m-AolHeader--newNav {
      .left-wrap {
        padding-top: 15px;
      }
      .navrail {
        > li {
          padding-left: 0;
          .user-profile-button {
            height: auto;
            .logout-button {
              display: none;
              @media screen and (min-width: map-get($breakpoints, l)) {
                display: block;
              }
              padding: 0;
              .text-label {
                font-size: 12px;
                padding: 0 0 16px 60px;
                color: $dolphin;
              }
              &:hover {
                background-color: initial;
                .text-label {
                  color: $dory;
                }
              }
            }
          }
          > a,
          .navrail_subitem,
          .user-profile-button > a {
            display: flex;
            &:hover {
              border-radius: 28px;
              background-color: $marshmallow;
            }
          }
        }
        .m-mtmhp-leftrail {
          $mtmhpBlue: #0047ff;
          background-color: transparent;
          border-radius: 30px;
          border: 1px solid $mtmhpBlue;
          height: auto;
          padding: 13px 0;
          margin-top: 24px;

          .mtmhp {
            .m-make-homepage {
              a {
                display: flex;
                align-items: center;
                justify-content: left;
                .icon {
                  fill: $mtmhpBlue;
                  margin: 0 0 0 20px;
                  width: 20px;
                  height: 20px;
                }

                .text-label {
                  color: $mtmhpBlue;
                  letter-spacing: 0;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 1.142;
                  margin: 0;
                  padding: 0 0 0 20px;
                  position: static;
                  text-transform: none;
                }

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }

          &:hover {
            background-color: $mtmhpBlue;
            .mtmhp {
              .m-make-homepage {
                a {
                  .icon {
                    fill: white;
                  }
                  .text-label {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, l)) {
    &.minimised {
      .left-wrap {
        width: 60px;

        .navrail li {
          .text-label,
          .text-label__badge,
          .navicon__img,
          .navrail-submenu {
            display: none;
          }

          .user-profile-button {
            height: 52px;
          }

          &.item--badge{
            a {
              .navicon {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;
              }
            }
          }
        }
      }
    }

    .title-bar {
      .o2-logo {
        background-size: 120px 26px;
        width: 120px;
      }
    }

    .desktop-user-navigation {
      display: flex;
      left: calc(100% - 320px);
      margin-top: 0;
      position: relative;
      top: -91px;
      width: 320px;

    }

    #header-form {
      left: 200px;
      max-width: calc(100% - 520px);
      width: 100%;
    }

    .left-wrap {
      width: 190px;
    }

    .navrail {
      width: 100%;

      .d-hidden{
        display: none;
      }

      li {
        a {
          .navicon {
            line-height: 20px;
          }
        }

        .user-profile-button {
          height: auto;
        }

        .text-label {
          color: $batcave;
          display: flex;
          font-family: $font-family-base;
          font-size: 14px;
          height: auto;
          letter-spacing: 0;
          margin-bottom: 1px;
          padding: 11px 10px 7px;
          position: relative;
          text-transform: none;
          top: 0;
          word-break: break-word;
          padding-left: 5px;
          &__badge{
            display: inline-block;
            margin-left: -8px;
          }
        }
        .navrail-submenu {
          background-color: transparent;
          display: inline-block;
          max-height: 250px;
          padding: 10px 0 0 40px;

          li {
            &:first-child{
              border-top: none;
            }
            border-bottom: none;
            padding-bottom: 0;

            &:hover {
              color: #26282a;
              opacity: 0.7;
            }
            a {
              height: 33px;
              padding: 0;

              .text-label {
                color: $batcave;
                display: flex;
                font-size: 14px;
              }
            }
          }
        }
        .navicon__img{
          display: block;
        }

        &.item--badge{
          a {
            .text-label {
              order: 2;
              padding-left: 10px;

              &_with_badge {
                order: 2;
              }
            }

            .navicon {
              display: none;
            }
          }
        }
      }

      &.smallLnav {
        li {
          &.item--badge {
            a {
              .navicon {
                display: flex;
              }
            }
          }
        }
      }
    }

    .m-mtmhp-leftrail {
      height: 48px;
      width: 100%;

      .mtmhp {
        width: 100%;

        .m-make-homepage {
          height: 100%;
          width: 100%;

          .icon {
            height: 15px;
            margin-left: 21px;
            margin-top: 0;
            width: 15px;
          }

          a {
            align-items: center;
            display: flex;
            height: 100%;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
          }

          .text-label {
            color: $white;
            display: flex;
            font-family: $font-family-base;
            font-size: 13px;
            letter-spacing: 0.1em;
            line-height: 19px;
            padding-left: 11px;
            text-align: left;
            transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1);
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, l)) and (max-width: 1350px){
    &.m-AolHeader--signedIn #header-form.header-form--smaller{
      width: calc(100% - 592px);
    }
  }
  &--banner {
    align-items: center;
    background-color: $dory;
    color: $white;
    display: flex;
    padding: 1em 0;
    text-align: center;
    width: 100%;
    z-index: 1;

    &.newsletter {
      margin-left: 100px;

      @media (max-width: 1415px) {
        margin-left: 0;
      }
    }

    span {
      display: block;
      font-size: 16px;
      margin: 0 auto;
      width: 75%;
    }
  }

  @media (min-width: 576px) {
    &--banner {
      padding: 1.5em 0;

      span {
        font-size: 18px;
        width: 45%;
      }
    }
  }

  &__closeBtn {
    display: none;
    height: 54px;
    width: 54px;
    position: fixed;
    right: 16px;
    border-radius: 50%;
    border: 1px solid $white;
    box-shadow: 0px 8px 12px rgb(0 0 0 / 15%);
    background: $white;
    margin-top: 28px;

    &--link {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      height: 14px;
    }
  }

  &.m-AolHeader--roundedSearch{
    $searchBoxShadow: 0px -1px 4px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.1);
    $searchBorderRadius: 24px;
    $searchInputHeight: 45px;
    $searchInputHeightMWeb: 41px;
    $verticalPadding: 20px;
    $topWrapHeight: $searchInputHeight + ($verticalPadding * 2);
    $searchBorderColor: $pebble;

    .m-AolHeader__spacer--header{
      height: 130px;
    }

    @media screen and (max-width: 900px){
      &.m-AolHeader--search-hidden .m-AolHeader--shadow,
      &.m-AolHeader--search-hidden .m-AolHeader--lnav_with_backdrop{
        top: 45px
      }
      .m-AolHeader--shadow,
      .m-AolHeader--lnav_with_backdrop {
        top: 115px;
      }
    }

    @media screen and (min-width: 901px) {
      .left-wrap{
        padding-top: 25px;
      }
      .top-wrap {
        height: $topWrapHeight !important;
        .desktop-user-navigation{
          top:-99px
        }
        .m-AolHeader--shadow {
          height: 42px;
          top: $topWrapHeight;
        }
 
        .m-AolHeader--lnav_with_backdrop {
          top: $topWrapHeight !important;
        }
        .title-bar{
          padding-top: 20px;
        }
      }
      .m-AolHeader__spacer--header{
        height: 120px;
      }
    }

    @media screen and (min-width: 901px) and (max-width: map-get($breakpoints, l)) {
      .top-wrap {
        .desktop-user-navigation {
          top: 9px;
        }
      }
    }

    #header-form{
      top: -($verticalPadding * 2);
      .header-search-form{
        max-width: 600px;
      }

      // This creates the containers box-shadow
      #header-form-search-input-list:after {
        content: "";
        display: none;
        position: absolute;
        pointer-events: none;
        top: -$searchInputHeight;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $searchBorderRadius;
        box-shadow: $searchBoxShadow;
      }


      &.form-interacted:focus-within .header-search-form,
      .header-search-form:hover,
      .header-search-form.wafer-autocomplete-active
      {
        #header-form-search-input-list:after{
          display: block;
        }
        #header-form-search-input,
        #header-form-search-button,
        #header-form-search-input-list ul
          {
          border-color: transparent;
        }
      }

      .header-search-form.wafer-autocomplete-active{
        #header-form-search-input-list{
          &:before{
            height: 1px;
            background-color: $searchBorderColor;
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 8px;
            right: 8px;
          }
        }
      }

      .wafer-autocomplete-list{
        display: none;
        &.wafer-autocomplete-list-active{
          display: block;
        }
      }

      #header-form-search-input{
        border-top-left-radius: $searchBorderRadius;
        border-bottom-left-radius: $searchBorderRadius;
        border-color: $searchBorderColor;
        border-right: none;
        padding: 0 0 0 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: $searchInputHeight;
        height: $searchInputHeight;
        color: $batcave;
        max-width: calc(100% - 61px);
        &::placeholder{
          line-height: $searchInputHeight;
          color: $dolphin;
          padding-left: 5px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      #header-form-search-button{
        border-top-right-radius: $searchBorderRadius;
        border-bottom-right-radius: $searchBorderRadius;
        background-color: white;
        border:1px solid $searchBorderColor;
        border-left: none;
        padding: 10px 20px;
        height: $searchInputHeight;

        svg{
          fill: $dory;
          width: 20px;
          height: 20px;
        }
      }

      #header-form-search-input-list {
        border-bottom-right-radius: $searchBorderRadius;
        border-bottom-left-radius: $searchBorderRadius;
        ul {
          border-color: $searchBorderColor;
          padding-top: 8px;

          li {
            font-size: 14px;
            padding: 8px 20px;

            &:hover {
              background-color: $marshmallow;
            }
          }

          &, li:last-of-type {
            border-bottom-right-radius: $searchBorderRadius;
            border-bottom-left-radius: $searchBorderRadius;
          }
          li:last-of-type {
            padding-bottom: 16px;
          }
        }
      }

      @media screen and (max-width: 900px) {
        padding: 16px 20px;
        background-color: white;

        .header-search-form{
          max-width: 100%;
        }

        #header-form-search-input{
          line-height: $searchInputHeightMWeb;
          height: $searchInputHeightMWeb;
          &::placeholder{
            line-height: $searchInputHeightMWeb;
            font-size: 12px;
          }
        }
        #header-form-search-button{
          height: $searchInputHeightMWeb;
        }

        #header-form-search-input-list:after {
          top: -$searchInputHeightMWeb;
        }
      }
    }

    &.m-AolHeader--mobile{
      @media screen and (max-width: 900px) {
        .m-AolHeader__spacer--header{
          height: 130px;
        }
      }
    }
  }

  &.m-AolHeader--navV2 {
    .top-wrap{
      height: 65px;
    }
    #user-navigation{
      width: 363px;
      @media screen and (min-width: map-get($breakpoints, l)){
        left: calc(100% - 363px);
      }
    }
    .user-navigation {
      position: relative;
      width: 100%;
      padding: 0;
      display: none;
      @media screen and (min-width: 901px){
        display: flex;
      }
      justify-content: space-between;
      align-items: center;
      > div{
        margin-right: 2px;
        margin-left: 2px;
      }

      &__btn{
        text-decoration: none;
      }
      &--right{
        justify-content: flex-end;
      }

      .m-wsrefresh{
        margin: 0;
        padding: 0;
        position: absolute;
        right: 24px;
        top: -12px;
        line-height: 0;

        a{
          font-weight: 600;
          line-height: 1;
        }
      }

    }

    .user-navigation__btn,
    .m-cs__btn,
    .m-browse__btn,
    .m-profile__btn{
      margin-top: 0;
      border: none;
      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      font-family: $font-family-poppins;
      background-color: white;
      padding: 16px 14px;
      border-radius: 50px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      color: $batcave;
      svg {
        margin-left: 8px;
        fill: $batcave;
      }
      &:hover, .m-flyout--open &{
        color: $accent_blue;
        background-color: $marshmallow;
        svg {
          fill: $accent_blue;
        }
      }
    }

    .m-profile__btn{
      font-weight: 600;
    }


    .m-profile,
    .m-browse{
      &__signin{
        display: block;
        font-size: 12px;
        line-height: 1;
        font-weight: 600;
        font-family: $font-family-poppins;
        text-decoration: none;
        border: 1px solid $accent_blue;
        color: $accent_blue;
        padding: 13px 20px;
        border-radius: 50px;
        margin-left: 16px;
        white-space: nowrap;

        &:hover{
          background-color: $accent_blue;
          color: white;
        }
      }
      &__btn--text{
        line-height: 1.25;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .m-flyout__btn{
        padding: 14px 24px;
      }
      .m-flyout__container{
        width: 212px;

        ul{
          list-style: none;
          margin: 0;
          padding: 12px;
          text-align: left;
          li{
            a{
              font-size: 14px;
              font-weight: 400;
              line-height: 1.142;
              letter-spacing: 0em;
              text-align: left;
              color: $batcave;
              background-color: white;
              padding: 16px;
              border-radius: 50px;
              text-decoration: none;
              margin-bottom: 3px;
              &:hover{
                color: $accent_blue;
                background-color: $marshmallow;
              }

              &.m-profile__signout{
                font-size: 12px;
                font-weight: 600;
                line-height: 1;
                letter-spacing: -0.02em;
                padding: 13px 20px;
                display: inline-block;
                color: $accent_blue;
                border: 1px solid $accent_blue;
                border-radius: 50px;
                &:hover{
                  background-color: $accent_blue;
                  color: white;
                }
              }

            }

            &.m-flyout__container--footer{
              padding: 19px 16px 11px;
              margin-top: 11px;
              border-top: 1px solid $dirty_seagull
            }
          }
        }
      }
    }
    .m-mail--new {
      position: relative;
      .m-mail-count{
        font-family: $font-family-poppins;
        top: 4px;
        right: 5px;
        border: 2px solid white;
        background-color: $accent_blue;
        line-height: .9;
      }
      .m-mail__lbl{
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        font-family: $font-family-poppins;
        color: $batcave;
        white-space: nowrap;
      }
      .m-mail__button {
        padding: 9px 14px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        svg {
          margin-left: 8px;
          fill: $batcave;
          display: block;
        }

        &:hover {
          .m-mail__lbl {
            color: $accent_blue;
          }
          background-color: $marshmallow;

          svg {
            fill: $accent_blue;
          }
        }
      }

      @media screen and (min-width: 901px) {
        .m-mail__button {
          padding: 9px 22px;
        }
      }
    }

    .m-header-flyouts{
      .m-flyout--overlay:after{
        background-color: $batcave;
        opacity: 0.92;
      }
      &__nav{
        .navrail{
          display: block;
          padding: 0;
          &__divider{
            display: none;
          }

          li{
            a,
            .navrail_subitem {
              padding: 16px 50px 16px 10px;

              .text-label{
                flex-grow: 1;
              }

            }
            &:after{
              content: "";
              display: block;
              height: 1px;
              width: calc(100% - 60px);
              background-color: $dirty_seagull;
              margin: 0 auto;
            }
          }
        }
      }
      &__acc{
        ul{
          list-style: none;
          padding: 0;
          margin: 0;

          li{
            a{
              font-size: 16px;
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0px;
              text-align: left;
              padding: 20px 30px;
              color: $batcave;
              text-decoration: none;
            }
            &:after{
              content: "";
              display: block;
              height: 1px;
              width: calc(100% - 60px);
              background-color: $dirty_seagull;
              margin: 0 auto;
            }
          }
        }
      }
      &__welcome{
        margin: 0;
        padding: 0 30px 8px;
        font-family: $font-family-poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        .logo{
          svg{
            display: inline-block;
            width: 75px;
          }
        }
        .m-profile__btn,
        .m-browse__btn{
          display: flex;
          margin-right: -24px;
        }
      }
      &__closeBtn{
        position: fixed;
        z-index: 9999;
        top: 50px;
        display: none;
        svg{
          fill: white;
        }
      }
      .m-flyout--open {
        ~ .m-header-flyouts__closeBtn {
          display: block;
        }
        &.m-header-flyouts__acc ~ .m-header-flyouts__closeBtn{
          left: 20px;
        }
        &.m-header-flyouts__nav ~ .m-header-flyouts__closeBtn{
          right: 20px
        }
      }
    }
    .m-AolHeader--shadow,
    .m-AolHeader--lnav_with_backdrop {
      top: 65px
    }
    .title-bar{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 10px 0;
      .logo{
        width: 96px;
        padding: 0;
        display: inline-block;
        flex-grow: 1;
        text-align: left;
        &-link{
          display: block;
        }
      }

      @media screen and (max-width: 900px){
        .logo {
          width: 80px;
        }
      }

      &__burger {
        line-height: 1;
        background-color: transparent;
        border: none;
        padding-right: 10px;
        margin-top: 1px;
        svg{
          fill: $batcave
        }
      }

      .m-profile__signin, .m-mail--new {
        display: block;
      }

      .m-profile__signin{
        font-size: 12px;
        padding: 9px 12px;
      }

      .m-profile__btn{
        padding: 9px 12px;
        svg {
          height: 24px;
          width: 24px;
        }
      }
      .m-mail--new {
        position: relative;
        svg {
          height: 27px;
        }
      }
    }
    .header-24_7-help {
      .m-profile__signin {
        margin-left: 10px;
      }
      .m-profile__btn svg {
        height: 22px;
        width: 22px;
      }
      .m-mail__button {
        margin-right: 0px!important;
        padding: 7px 11px 8px 5px;
        .m-mail-count--usernav {
          top: 0px!important;
          right: -2px!important;
          padding: 3px 5px;
        }
      }
      .m-profile__signin, .m-profile__btn--text {
        font-size: 10px;
      }
    }

    #header-form #header-form-search-button svg{
      fill: $accent_blue;
    }
    @media screen and (max-width: 900px) {
      .m-mail--new{
        .m-mail__lbl{
          display: none;
        }
      }
      #header-lnav a{
        display: none;
      }
      #header-lnav-mweb .navrail a,
      #header-lnav-mweb .navrail .navrail_subitem{
        display: flex !important;
        .count{
          left: 42px;
          font-size: 11px;
          width: 16px;
          height: 16px;
        }
      }

      &.m-AolHeader--search-hidden .m-AolHeader--shadow,
      &.m-AolHeader--search-hidden .m-AolHeader--lnav_with_backdrop {
        top: 65px
      }
      .m-AolHeader--shadow,
      .m-AolHeader--lnav_with_backdrop{
        top: 130px;
      }
      &.m-AolHeader--roundedSearch .m-AolHeader__spacer--header{
        height: 145px ;
      }
      &.games {

        .m-AolHeader__spacer--header{
          height: 80px ;
        }
        .add-space{
          margin-bottom: 75px;
        }
      }
    }

    @media screen and (min-width: 901px) {
      .m-AolHeader--shadow,
      .m-AolHeader--lnav_with_backdrop {
        top: 60px
      }
      .top-wrap {
        .m-AolHeader--innerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          padding: 0 5px;

          #header-form {
            position: static;
            flex: 1 1 100%;
            max-width: none;
            padding-left: 11%;
          }

          #user-navigation {
            position: static;
            flex: 1 1 40%;

            .user-navigation {
              position: relative;
            }
          }

        }
        .title-bar {
          padding: 0;
          margin-left:10px ;
          flex: 0 0;

          &__burger, .m-profile__btn {
            display: none;
          }

          .m-profile__signin, .m-mail--new {
            display: none;
          }
        }
        .header-24_7-help {
          .m-flyout--overlay {
            display: none;
          }
        }
      }

      #header-lnav{
        .navrail {
          > li {
            > a {
              .navicon.active svg {
                fill: $accent_blue
              }
              .text-label.active {
                color: $accent_blue;
              }
              &:hover {
                .navicon svg {
                  fill: $accent_blue
                }
                .text-label {
                  color: $accent_blue;
                }
              }
            }
          }
        }
      }
    }


    @media screen and (min-width: 901px)  and (max-width: map-get($breakpoints, l)){
      .top-wrap {
        .m-AolHeader--innerContainer {
          #header-form {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

@media print {
  .m-AolHeader {
    &.m-AolHeader--navV2 {
      display: none;
    }
  }
}

.m-cs{
  color: $batcave;

  .m-flyout__container{
    width: 277px;
  }

  &__btn{
    border: none;
    font-size: 14px;
    line-height: 0.857;
    font-weight: 400;
    background-color: white;
    padding: 18px 24px;
    border-radius: 50px;
    margin-top: -10px;
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 8px;
      fill: $batcave;
    }

    &:hover, .m-flyout--open &{
      color: $accent_blue;
      background-color: $marshmallow;
      svg {
        fill: $accent_blue;
      }
    }
  }

  &__title{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    margin: 30px 0 22px;
  }

  &__number{
    font-family: $font-family-poppins;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 25px 0;
    color: inherit;
    text-decoration: none;
    display: block;
  }

  &__morelink{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.167;
    letter-spacing: -0.2px;
    padding: 16px 0 16px;
    border-top: 1px solid $grey-hair;
    color: inherit;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;

    svg{
      margin-left: 8px;
      vertical-align: middle;
    }

    &:hover{
      background-color: $marshmallow;
      color: $picasso;

      svg{
        fill: $picasso;
      }
    }
  }
}

.special-event-wrapper {
  .m-aol-se {
    &__container_1 {
      display: none;
    }

    &__container_2 {
      display: none;

      @media screen and (min-width: 901px) {
        display: flex;
      }
    }
  }

  @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
    display: none;
  }
}
.m-AolHeader--special_event {
  @media screen and (min-width: 901px) {
    &.m-AolHeader.m-AolHeader--grid .m-AolHeader__spacer--header {
      height: 145px !important;
    }

    &.m-AolHeader.m-AolHeader--roundedSearch .top-wrap {
      height: 110px !important;
    }

    .top-wrap {
      height: 110px !important;

      .m-AolHeader--innerContainer {
        bottom: 40px;
        position: relative;
      }

      .m-AolHeader--shadow,
      .m-AolHeader--lnav_with_backdrop {
        top: 110px !important;
      }
    }
  }

  @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
    &.m-AolHeader {
      &.m-AolHeader--sticky .m-AolHeader__top {
        margin-bottom: 0px;
      }
      &.m-AolHeader--navV2.m-AolHeader--roundedSearch .m-AolHeader__spacer--header {
        height: 130px;
      }
    }
  }
}