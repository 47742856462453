@import '../../../base/assets/scss/_base/config';

$height-default: 72px;
$background-default: $white;

.m-banner {
  position: relative;
  height: $height-default;
  overflow: hidden;
  color: $batcave;
  font-size: 16px;
  text-align: left;

  @media screen and (max-width: 900px){
    .m-banner--desktop-only{
      display: none;
    }
  }
  @media screen and (min-width: 900px){
    .m-banner--mobile-only{
      display: none;
    }
  }

  &.m-banner--hidden {
    display: none;
  }

  .hasScrolled &.m-banner--transition,
  &.m-banner--transition {
    &,
    .m-banner__inner--container {
      transition: height $speed-slower $ease, visibility $speed-slower $ease;
    }

    .m-banner__graphic--container {
      transition: height 0s, visibility $speed-slower $ease;
    }

    &.m-banner--hidden {
      &,
      .m-banner__inner--container,
      .m-banner__graphic--container {
        height: 0 !important;
        display: block;
        visibility: hidden;
        transition: height $speed-slower $ease, visibility $speed-slower $ease $speed-slower;
      }
    }
  }

  &__inner {
    height: 100%;
    &--container {
      left: 0;
      right: 0;
      z-index: 200;
      position: fixed;
      height: $height-default;
      background-color: $background-default;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.05), transparent);
      overflow: hidden;
    }
    margin: 0 auto;
    width: 100%;
    max-width: 1405px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__title {
    font-size: 1.25em;
    font-weight: 800;
    line-height: 1;
  }

  &__copy {
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
  }

  &__btn {
    color: inherit;
    text-decoration: none;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1.333;
    border: none;
    height: 40px;
    padding: 0 21px;
    text-align: center;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__graphic{
    &--container{
      height: $height-default;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &--privacy{
    $privacy-desktop-height: 92px;
    $privacy-mobile-height: 112px;
    height: $privacy-desktop-height;
    .m-banner {
      &__inner{
        justify-content: center;
        &--container{
          background-image: none;
          background-color: $peeps-lighter;
          height: $privacy-desktop-height;
        }
      }
      &__graphic{
        position: absolute;
        height:$privacy-desktop-height;
        top: 0;
        right: -115px;
        width: 57px;
        background-color: $peeps-light;

        &:before, &:after{
          position: absolute;
          content: "";
          height: 110px;
          width: 110px;
          border-radius: 100%;
          background-color: $peeps-light;
          right: 57px;
          top: -5px;
        }

        &:after{
          height: 190px;
          width: 190px;
          right: 167px;
          top: -115px;
        }

        &--container{
          height: $privacy-desktop-height;
        }
        &--logo{
          position: absolute;
          top: -16px;
          left: -146px;

          svg{
            width: 368px;
            fill: $peeps_light;
          }
        }
      }

      &__logo{
        padding-left: 15px;
        svg{
          width: 80px;
          height: 100px;
          fill: $dory;
        }
      }
      &__title{
        font-weight: 700;
        font-family: $font-family-poppins;
        margin: 0 0 8px 0;
      }
      &__copy{
        font-weight: 500;
        line-height: 1.375;
        margin: 0;
      }

      &__btn{
        height: 36px;
        padding: 0 28px;
        &--close{
          background-color: $dory;
          color: white;
        }
        &--link{
          white-space: nowrap;
          background-color: $peeps_lighter;
          color: $dory;
          border: 1px solid $dory;
          font-weight: 500;
        }
      }

      &__content-container{
        margin: 0 80px 0 100px;
      }
    }

    @media screen and (max-width: 900px){
      height: $privacy-mobile-height;
      .m-banner{
        &__inner{
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          padding: 16px 20px;
          flex-wrap: wrap;
          &--container{
            height: $privacy-mobile-height;
          }
        }
        &__graphic {
          height: $privacy-mobile-height;
          top: -16px;
          right: 0;
          &--container{
            height: $privacy-mobile-height;
          }
          &--logo{
            display: none;
          }
        }

        &__logo{
          display: none;
        }
        &__title{
          font-weight: 700;
          font-size: 1em;
          line-height: 1.25;
          margin: 0 0 8px 0;
          max-width: 100%;
          width: 100%;
          flex: 1 0 100%;
        }
        &__copy{
          font-size: .875em;
          padding-right: 10px;
          margin: 0;
          flex: 1 1;
          align-self: center;
        }
        &__btn{
          &--container{
            align-self: center;
            flex: 0 1;
          }
          &--close{
            background-color: $dory;
            color: white;
          }
        }
      }
    }

    @media screen and (max-width: 350px){
      font-size: 14px;
      height: $privacy-mobile-height;
      .m-banner{
        &__title{
          margin: 0 0 6px 0;
        }
        &__copy{
          margin: 0 0 6px 0;
        }
        &__btn{
          height: 30px;
          padding: 0 20px;
        }
      }
    }
  }

  &--care {
    color: $white;
    .m-banner {
      &__inner--container {
        padding: 0 15px;
        background-color: $solo-cup;
        //background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.182) 0%, rgba(0, 0, 0, 0) 71.95%, rgba(0, 0, 0, 0) 138.31%)
      }
      &__copy{
        max-width: 500px;
      }
      &__btn {
        white-space: nowrap;
        color: $solo-cup;
        background-color: $white;
      }
      &__graphic{
        position: absolute;
        display: block;
        height: 400%;
        width: 150px;
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
        background-color: $solo_cup_darker;
        bottom: 0;
        left: -110px;

        &--right{
          left: auto;
          right: -175px;
          bottom: -100px;

          &:before{
            content: "";
            display: block;
            height: 0;
            width: 0;
            border-right: 114px solid $solo_cup_darker;
            border-top: 114px solid transparent;
            position: absolute;
            bottom: 100px;
            right: 225px;

          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      .m-banner {
        &__title {
          max-width: 230px;
          font-size: 1em;
        }

        &__copy{
          font-size: .9em;
        }

        &__graphic{
          &--right{
            &:before{
              right: 160px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 750px) {
      .m-banner {
        &__copy{
          display: none;
        }
      }
    }
  }

  &--mtmhp {
    color: $white;
    .m-banner {
      &__inner{
        justify-content: flex-start;
      }
      &__inner--container {
        background-color: $dory;
        padding: 0 15px;
      }
      &__title{
        font-family: $font-family-poppins;
        font-size: 1.125em;
        font-weight: 600;
      }
      &__copy{
        flex-grow: 1;
        padding-left: 60px;
      }
      &__btn {
        font-family: $font-family-poppins;
        font-size: 0.875em;
        color: $dory;
        background-color: $white;
        white-space: nowrap;
        &--close {
          color: $white;
          background-color: transparent;
        }

        &--container{
          padding-left: 12px;
        }

      }
      &__graphic{
        position: absolute;
        display: block;
        border-radius: 50%;
        border: 142px solid transparent;
        border-top-color: $dory_dark;
        width: 0;
        height: 0;
        top: -37px;
        left: -89px;
        transform: rotate(45deg);

        &:before{
          position: absolute;
          content: "";
          border-radius: 50%;
          border: 142px solid transparent;
          border-right-color: $dory_darker;
          border-bottom-color: $dory_darker;
          top: -123px;
          left: -326px;
          transform: rotate(0);
        }

        &--right{
          left: auto;
          right: -125px;
          top: -150px;
          border-width: 115px;
          border-color: transparent;
          border-right-color: $dory_darker;

          &:before{
            content: "";
            border-width: 175px;
            border-right-color: $dory_dark;
            border-bottom-color: $dory_dark;
          }

          &:after{
            position: absolute;
            content: "";
            display: block;
            border: 91px solid transparent;
            border-left-color: $dory_darker;
            border-top-color: $dory_darker;
            border-radius: 50%;
            transform: translate(-265px, 235px);
          }
        }
      }
    }
    @media screen and (max-width: 820px){
      .m-banner {
        &__inner {
          justify-content: space-between;
        }
        &__copy{
          display: none;
        }
      }
    }
  }

  &--chromeAppBanner {
    .m-banner {
      &__inner {
        justify-content: flex-start;
        &--container {
          position: static;
          background-color: $white;
          background-image: none;
          border: 1px solid $dirty_seagull;
        }
      }
      &__img {
        box-sizing: content-box;
        height: 44px;
        width: 44px;
        margin-right: 1em;
        border-radius: 10px;
      }
      &__btn {
        color: $dory;
        background-color: $white;

        &--close {
          background-color: transparent;
          padding: 0 12px;

          svg {
            height: 12px;
            width: 12px;
            fill: $bob;
          }
        }
        &--prompt {
          background-color: $picasso;
          color: $white;
          font-size: 1em;
          line-height: 1;
          font-weight: 800;
          padding: 10px 20px;
          height: auto;
          margin-right: 24px;
        }
      }
      &__content {
        flex-grow: 1;
        text-align: left;
      }
      &__title {
        margin: 0 0 6px 0;
        font-weight: 800;
        font-size: 1.125em;
      }
      &__copy {
        margin: 0;
        font-size: 0.75em;
        font-weight: 600;
      }
      &__graphic {
        position: absolute;
        display: block;
        height: 200%;
        width: 160px;
        border-radius: 100%;
        background-color: $denim;
        right: -75px;
        top: -115px;

        &:before,
        &:after {
          content: '';
          position: absolute;
          display: block;
          border-radius: 100%;
        }

        &:before {
          height: 57%;
          width: 50%;
          background-color: $peeps;
          right: 165px;
          top: 58px;
        }

        &:after {
          height: 200%;
          width: 200%;
          background-color: $sea_foam_lighter;
          right: -75px;
          top: 140px;
          z-index: -1;
        }

        &--container {
          overflow: hidden;
          &:after,
          &:before {
            z-index: 1;
            content: '';
            position: absolute;
            display: block;
            height: 60%;
            width: 12px;
            background-color: $peeps;
            right: 0;
            bottom: 0;
          }

          &:before {
            height: 100%;
            background-color: #13a9ff;
            top: 0;
            bottom: auto;
          }
        }
      }
    }
  }

  &--bannerAd{
    height: auto;
    .m-banner{
      &__inner {
        max-width: none;
        display: block;
        &--container {
          height: auto;
          position: static;
          background-color: #f4f4f6;
          background-image: linear-gradient(0deg, #ebebeb 0%, rgba(255, 255, 255, 0.00) 100%);
          background-size: 100% 11px;
          background-repeat: no-repeat;
          background-position: bottom;
        }
      }
    }
    .m-gam__container{
      margin: 0 !important;

      .m-gam--loaded,
      .m-gam__placeholder{
        margin: 6px auto;
      }
    }
  }

  &--consentjs{
    height: 0; // This gets overridden via JS
    background-color: #f4f4f6;
    .m-banner__inner--container{
      display: none;
    }
  }
}
